import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronsRight } from 'lucide-react';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { BottomButton } from '../../components/BottomButton';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';

interface LandingContentProps {
  checkupRequest: CheckupRequestInfo;
}

export const LandingContent: FunctionComponent<LandingContentProps> = ({ checkupRequest }) => {
  const navigate = useNavigate();

  let vetFullName = `${checkupRequest?.vet_first_name} ${checkupRequest?.vet_last_name}`.trim();
  if (vetFullName.length === 0) {
    vetFullName = 'Your Vet';
  }

  const petName = checkupRequest?.pet_name || 'your pet';

  return (
    <div className="flex h-full w-full flex-1 flex-col justify-between px-4 pb-2">
      <div className="mt-10 text-center text-base portrait:text-xl landscape:text-base">
        <p>
          <strong>{vetFullName}</strong> has requested a checkup video of <strong>{petName}</strong>.
        </p>
        <p>Click the button below to get started.</p>
      </div>
      <BottomButton
        onClick={() => {
          navigate('./checkup');
        }}
      >
        Get Started <ChevronsRight size="24" className="ml-1" />
      </BottomButton>
      <NeedHelpContactSupport className="text-center" />
    </div>
  );
};
