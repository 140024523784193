import React, { FunctionComponent } from 'react';
import { FileVideoIcon, VideoIcon } from 'lucide-react';
import * as Sentry from '@sentry/browser';
import { NativeCapture } from '@companion-professional/components';
import { TapButton } from '../TapButton';
import { useSetCameraPreviewEnabled } from '../../state/camera';

interface InBrowserCaptureButtonsProps {
  // onVideoSelected is a function that is called when a video is selected (e.g. in-browser capture or file picker)
  onVideoSelected: (file: File) => void;
}

// InBrowserCapture is a component that allows the user to capture a video in the browser (not the native camera app)
// or upload a file.
export const InBrowserCaptureButtons: FunctionComponent<InBrowserCaptureButtonsProps> = ({ onVideoSelected }) => {
  const setCameraPreviewEnabled = useSetCameraPreviewEnabled();

  return (
    <div className="flex-2 flex w-full items-start justify-center">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <TapButton
          onClick={() => {
            Sentry.addBreadcrumb({
              category: 'checkup_flow',
              message: 'In browser camera capture opened'
            });
            setCameraPreviewEnabled(true);
          }}
        >
          <VideoIcon className="hidden sm:block" /> Open Camera
        </TapButton>

        <div className="font-bold text-widget-foreground">- or -</div>

        <NativeCapture captureType="video" nativeFilePicker onFileSelected={onVideoSelected} className="w-full">
          <TapButton
            onClick={() => {
              Sentry.addBreadcrumb({
                category: 'checkup_flow',
                message: 'File picker opened'
              });
            }}
          >
            <FileVideoIcon className="hidden sm:block" /> Click here to select a file to upload
          </TapButton>
        </NativeCapture>
      </div>
    </div>
  );
};
