import React, { FunctionComponent, useEffect } from 'react';
import { CheckCircleIcon } from 'lucide-react';
import { useSetHeaderBarTitle } from '../../state/headerBar';
import { NeedHelpContactSupport } from '../../components/NeedHelpContactSupport';

// CheckupComplete is the page that is shown to the Pet Parent when they have completed the checkup.
export const CheckupComplete: FunctionComponent = () => {
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    setHeaderBarTitle('Checkup Complete');
  }, []);

  return (
    <div className="flex flex-1 flex-col justify-center">
      <div className="space-y-4 p-4">
        <div className="flex flex-row items-center justify-center gap-2">
          <CheckCircleIcon className="h-8 w-8 text-primary" />
          <div className="text-xl font-bold text-primary md:text-2xl">Checkup Complete!</div>
        </div>
        <div className="text-center text-gray-700">
          You can now close this tab. The results will be sent to your veterinarian.
        </div>
        <NeedHelpContactSupport className="text-center" />
      </div>
    </div>
  );
};
