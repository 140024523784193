import React, { FunctionComponent } from 'react';
import { FileVideoIcon, VideoIcon } from 'lucide-react';
import * as Sentry from '@sentry/browser';
import { NativeCapture } from '@companion-professional/components';
import { isMobile } from '../../lib/ua';
import { TapButton } from '../TapButton';
import { MobileCameraHelpLink } from './MobileCameraHelpLink';

interface NativeCameraCaptureButtonsProps {
  // onVideoSelected is a callback function that is called when a video has been filmed using the native camera or
  // selected from the file picker
  onVideoSelected: (file: File) => void;
}

// NativeCameraCaptureButtons is a component that displays a button that allows the user to capture a video using the
// native camera app (if mobile) and a button for selecting a video from the file picker.  When a video is accepted
// ("use this video") or selected with the file picker, the onVideoSelected callback is called with the selected file.
export const NativeCameraCaptureButtons: FunctionComponent<NativeCameraCaptureButtonsProps> = ({ onVideoSelected }) => {
  let moreOptionsText = 'Click here to select a file to upload';
  if (isMobile()) {
    moreOptionsText = 'Tap here for more upload options';
  }

  return (
    <div className="flex items-center justify-center">
      <div className="mx-4 mt-6 flex w-full flex-col items-center justify-center">
        {isMobile() ? (
          <NativeCapture captureType="video" onFileSelected={onVideoSelected} className="w-full">
            <TapButton
              onClick={() => {
                Sentry.addBreadcrumb({
                  category: 'checkup_flow',
                  message: 'Native camera capture opened'
                });
              }}
            >
              <VideoIcon className="hidden sm:block" /> Tap here to capture the video
            </TapButton>
          </NativeCapture>
        ) : null}

        {isMobile() ? <div className="font-bold text-widget-foreground">- or -</div> : null}

        <NativeCapture captureType="video" nativeFilePicker onFileSelected={onVideoSelected} className="w-full">
          <TapButton
            onClick={() => {
              Sentry.addBreadcrumb({
                category: 'checkup_flow',
                message: 'File picker opened'
              });
            }}
          >
            <FileVideoIcon className="hidden sm:block" /> {moreOptionsText}
          </TapButton>
        </NativeCapture>
        <MobileCameraHelpLink />
      </div>
    </div>
  );
};
