import React, { FunctionComponent, useEffect } from 'react';
import { SplitLayout } from '../../components/SplitLayout';
import { LandingContent } from './LandingContent';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { useSetHeaderBarTitle } from '../../state/headerBar';

interface CheckupProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupLanding is the first page that is shown to the Pet Parent when they click on the link to the checkup.  It
// contains a greeting and action button(s) for starting the checkup.
export const CheckupLanding: FunctionComponent<CheckupProps> = ({ checkupRequest }) => {
  const setHeaderBarTitle = useSetHeaderBarTitle();

  useEffect(() => {
    setHeaderBarTitle(`Checkup for ${checkupRequest?.pet_name || 'your pet'}`);
  }, [checkupRequest?.pet_name]);

  return (
    <SplitLayout>
      <LandingContent checkupRequest={checkupRequest} />
    </SplitLayout>
  );
};
