import React, { FunctionComponent, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { CheckupRequestInfo } from '@companion-professional/dctypes';
import { CheckupLanding } from './pages/CheckupLanding';
import { Checkup } from './pages/Checkup';
import { CheckupComplete } from './pages/CheckupComplete';
import { AndroidHelp } from './pages/AndroidHelp';
import { IOSHelp } from './pages/IOSHelp';
import { useSetFeatureFlagContext } from './hooks/useSetFeatureFlagContext';

const SentryRoutes = SentryReact.withSentryReactRouterV6Routing(Routes);

interface CheckupRoutesProps {
  checkupRequest: CheckupRequestInfo;
}

// CheckupRoutes contains all the routes for the checkup flow.
export const CheckupRoutes: FunctionComponent<CheckupRoutesProps> = ({ checkupRequest }) => {
  useSetFeatureFlagContext(checkupRequest.clinic_id);

  useEffect(() => {
    // Adding some context to the Sentry scope for the checkup
    Sentry.setContext('checkup', {
      checkup_id: checkupRequest.id,
      clinic_id: checkupRequest.clinic_id,
      pet_id: checkupRequest.pet_id,
      pet_name: checkupRequest.pet_name
    });
  }, [checkupRequest]);

  return (
    <SentryRoutes>
      <Route index element={<CheckupLanding checkupRequest={checkupRequest} />} />
      <Route path="/checkup" element={<Checkup checkupRequest={checkupRequest} />} />
      <Route path="/complete" element={<CheckupComplete />} />
      <Route path="/android-help" element={<AndroidHelp />} />
      <Route path="/ios-help" element={<IOSHelp />} />
    </SentryRoutes>
  );
};
