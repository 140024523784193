import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { cn } from '@companion-professional/webutils';
import { Home } from './pages/Home';
import { GenerateError } from './components/GenerateError';
import { CheckupLoader } from './CheckupLoader';
import { ClinicLanderLoader } from './ClinicLanderLoader';
import { useCameraPreviewEnabled } from './state/camera';
import { HeaderBar } from './components/HeaderBar';

declare const __LAUNCH_DARKLY_CLIENT_ID__: string;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: FunctionComponent = () => {
  const cameraPreviewEnabled = useCameraPreviewEnabled();

  return (
    <div
      className={cn('flex max-h-[60rem] w-full flex-1 flex-col items-center justify-between overflow-y-auto', {
        'bg-brand-background': !cameraPreviewEnabled,
        // This is a hack to make the camera preview take up the full screen and push the header (tabs) off the screen.
        'landscape:h-[101vh]': cameraPreviewEnabled
      })}
    >
      <HeaderBar hidden={cameraPreviewEnabled} />
      <SentryRoutes>
        <Route index element={<Home />} />
        <Route path="/generate-error" element={<GenerateError />} />
        <Route path="/c/:clinicSlug" element={<ClinicLanderLoader />} />
        <Route path="/:checkupId/*" element={<CheckupLoader />} />
      </SentryRoutes>
    </div>
  );
};

// AppWithLDProvider is a wrapper around the App component that provides the LaunchDarkly context. This is required for
// LaunchDarkly to work.
export const AppWithLDProvider = withLDProvider({
  clientSideID: __LAUNCH_DARKLY_CLIENT_ID__
})(App);
